import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { LoginFacade } from 'src/app/login/facade/login.facade';
import { BrandService } from '../../shared/services/brand.service';

@Component({
  selector: 'error-component',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  error_code: string | null = null;
  error_msg: string | null = null;
  brandConfig: any = undefined;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private brandService: BrandService,
    private loginFacade: LoginFacade
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.pipe(map(p => p.get("message")), take(1)).subscribe(value => {
      if (value !== undefined && !!value) {
        this.error_msg = value.toString();
        this.error_code = '...redirecting...';
        this.router.navigate([], { queryParams: { 'message': null }, queryParamsHandling: 'merge' });
        const localTimer = window.setTimeout(() => {
          this.loginFacade.logOut();
          clearTimeout(localTimer);
        }, 6000);
      }
    });

    this.brandService.config$
      .pipe(take(1))
      .subscribe((config: any) => {
        this.brandConfig = config;
      });
  }

  redirectToLogin() {
    this.loginFacade.logOut();
  }
}
