import { Component, OnDestroy, Inject, OnInit, Renderer2 } from '@angular/core';

import { Subject } from 'rxjs';
import { LoginFacade } from './login/facade/login.facade';
import { filter, take, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { AuthFacadeService } from './auth/services/auth-facade.service';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LangStateService } from './shared/lang/lang-state.service';
import { ThemeService } from './shared/services/theme.service';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { DEFAULT_BRAND_NAME, IDLE_SESSION_TIMEOUT_SECONDS } from './shared/constants/constants';
import { MatDialog } from '@angular/material/dialog';
import { IdleComponent } from './shared/idle/idle.component';
import { Title } from '@angular/platform-browser';
import { BrandService } from './shared/services/brand.service';
import { LoadingService } from "./shared/services/loading.service";
import { GoogleTagManagerService } from "./googletagmanager/google-tag-manager.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnDestroy, OnInit {
  private loadTriggered = false;
  readonly terminated$: Subject<boolean> = new Subject<boolean>();
  modelOpen: boolean;
  isLoginPage: boolean = false;
  index: number = 0;

  constructor(private authFacadeService: AuthFacadeService,
    private loginFacadeService: LoginFacade,
    private router: Router,
    private translateService: LangStateService,
    @Inject(DOCUMENT) private document: Document,
    private themeService: ThemeService,
    private renderer2: Renderer2,
    private bnIdle: BnNgIdleService,
    public dialog: MatDialog, private titleService: Title,
    private brandService: BrandService,
    public loadingService: LoadingService,
    private gtmService: GoogleTagManagerService
  ) {
    const hostname: string[] = location.hostname.split(".");
    const brandName: string = this.brandService.discoverBrand(hostname) || DEFAULT_BRAND_NAME;
    this.brandService.setConfig(brandName);
    this.brandService.config$.pipe(filter(config => config !== undefined), takeUntil(this.terminated$)).subscribe((config: any) => {
      this.titleService.setTitle(config.name);
      this.themeService.setTheme(config.id, this.renderer2);
      this.themeService.setFavIco(config.favicon.name);
    });

    this.translateService.isChanging$().subscribe(value => {
      this.document.documentElement.lang = value;
    });

    this.router.events.pipe(
      takeUntil(this.terminated$),
      filter(event => event instanceof NavigationEnd),
    ).subscribe(event => {
      const navEndEvent = event as NavigationEnd;
      if ((!navEndEvent?.url.startsWith('/status') &&
        !navEndEvent?.urlAfterRedirects.startsWith('/status')) &&
        (!navEndEvent?.url.startsWith('/health') &&
          !navEndEvent?.urlAfterRedirects.startsWith('/health')) &&
        (!navEndEvent?.url.startsWith('/maintenance') &&
          !navEndEvent?.urlAfterRedirects.startsWith('/maintenance'))
      ) {
        if (!this.loadTriggered) {
          this.authFacadeService.loadAuth();
          this.loadTriggered = true;
          console.log("loading auth");
        }
        this.isLoginPage = navEndEvent.url.startsWith('/login');
        if (this.router.getCurrentNavigation()?.previousNavigation?.finalUrl?.toString().startsWith('/account-details') && this.authFacadeService.forceUpdateUserFlag) {
          this.loadingService.setLoading(true);
          this.authFacadeService.updateUserSingle(this.authFacadeService.forceUpdateServiceNumber);
          this.authFacadeService.forceUpdateUserFlag = false;
          console.log("updating user information");
        }
      }

      this.gtmService.logPageView(navEndEvent.url);
    });

    this.authFacadeService.userSingle$
      .pipe(distinctUntilChanged(),
        takeUntil(this.terminated$))
      .subscribe((user) => {
        if (user !== undefined && user?.userSingle?.user?.id && !this.authFacadeService.timerTriggered) {
          this.authFacadeService.timerTriggered = true;
          // this.tabService.init();
          this.bnIdle.startWatching(IDLE_SESSION_TIMEOUT_SECONDS).pipe(takeUntil(this.terminated$))
            .subscribe((isTimedOut: boolean) => {
              if (isTimedOut && !this.modelOpen) {
                this.displaySessionModal();
              }
            }
            );
        }
      }
      );
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.closingVariables();
    // this.tabService.ngOnDestroy();
  }

  private closingVariables() {
    this.authFacadeService.timerTriggered = false;
    this.modelOpen = false;
    this.terminated$.next(true);
  }

  private displaySessionModal() {
    if (!this.isLoginPage) {
      this.modelOpen = true;
      this.authFacadeService.startExpiringProcess();
      const dialogRef = this.dialog.open(IdleComponent, {
        disableClose: true,
        hasBackdrop: true,
        width: '500px'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.modelOpen = false;
        if (result) {
          this.authFacadeService.refreshToken();
          this.bnIdle.resetTimer();
        } else {
          this.authFacadeService.timerTriggered = false;
          this.bnIdle.stopTimer();
          this.dialog.closeAll();
          const portalSessionID = localStorage.getItem("portalSessionID") || "";
          if (portalSessionID !== undefined) {
            try {
              this.authFacadeService.webMyAccountLogout(portalSessionID);
            } catch (err) {
              console.log(`Error legacy logout`, err);
            }
          }
          this.loginFacadeService.logOut();
        }
      });
    } else {
      this.closingVariables();
    }
  }

}





